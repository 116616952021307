* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-size: cover;
}
iframe {
  z-index: unset !important;
  position: unset !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(to right, #f7d0ff, #fdefff);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #2e1134, #64256f);
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  --webkit-transition: all 0.5s ease-in;
  --moz-transition: all 0.5s ease-in;
  --o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to right, #43194b, #64256f);
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #212529;
}
p {
  margin: 0;
}
button {
  border: none;
  outline: none;
  box-shadow: none;
}
button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: transparent !important;
}
input,
select {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:focus {
  border-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: transparent;
  border-style: none;
  border-width: 0;
}
.pointer:hover {
  cursor: pointer;
  color: rgb(153, 101, 4);
}
.rounded__corners {
  border-radius: 26px;
  border: 4px solid rgb(48, 44, 44);
}
.banner__image {
  max-width: 90%;
}
.action__column {
  font-size: 11px;
  min-width: 97px;
}
.margin__right {
  margin-right: 10px;
}
.margin__left {
  margin-left: 10px;
}
.margin__five {
  margin: 5px;
}
.margin__ten {
  margin: 10px !important;
}
.margin__left__three {
  margin-left: 3px;
}
.ml__auto {
  margin-left: auto !important;
}
.mr__auto {
  margin-right: auto !important;
}
.padding__five {
  padding: 5px !important;
}
.padding__ten {
  padding: 10px !important;
}
.small__text {
  font-size: 8px !important;
}
.small__info {
  font-size: 10px !important;
}
.text__right {
  text-align: right !important;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.hide {
  display: none !important;
}
.nfttunz__loader__wrapper {
  z-index: 999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
}
.nfttunz__navbar__toggler {
  border: none;
  outline: none;
}
.nfttunz__navbar__toggler:hover i {
  color: #000;
}
.nfttunz__navbar__toggler:focus {
  border: none;
  outline: none;
}
.nfttunz__navbar {
  color: #000 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  width: 100%;
  /* border-bottom: 8px solid #eee; */
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999; */
}
.nfttunz__navbar__landing {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  color: #000 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  width: 100%;
  /* border-bottom: 8px solid #eee; */
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999; */
}
.nfttunz__navbar__brand {
  width: auto;
}
.nfttunz__navbar button {
  color: #000 !important;
}
.user__profile__dropdown {
  margin-top: 10px !important;
}
.nfttunz__navbar__search__wrapper {
  background-color: #eee;
  display: flex;
  border-radius: 25px;
  height: max-content;
  align-self: center;
  padding: 4px 15px;
}
.nfttunz__login__button {
  background-image: linear-gradient(to right, #963ca8, #64256f) !important;
  display: flex;
  border-radius: 15px;
  padding: 6px 12px;
  font-size: 12px;
  border: 1px solid transparent;
  -o-transition: 0.3 all ease-in;
  -moz-transition: 0.3 all ease-in;
  -webkit-transition: 0.3 all ease-in;
  transition: 0.3s all ease-in;
}
.nfttunz__login__button button {
  background: transparent !important;
  color: #eee !important;
}
.nfttunz__login__button:hover {
  border: 1px solid #eee;
}
.nfttunz__register__button {
  background-image: linear-gradient(to right, #2e1134, #64256f) !important;
  display: flex;
  border-radius: 15px;
  border: none !important;
  padding: 6px 12px;
  font-size: 12px;
  border: 1px solid transparent;
  -o-transition: 0.3 all ease-in;
  -moz-transition: 0.3 all ease-in;
  -webkit-transition: 0.3 all ease-in;
  transition: 0.3s all ease-in;
}
.nfttunz__register__button:hover {
  background-color: transparent;
  color: #eee !important;
  border: 1px solid #eee;
}
.nfttunz__register__button:hover .nav__register__button > span {
  color: #eee !important;
}
.nfttunz__navbar__search__wrapper input {
  background-color: transparent;
  border: none !important;
}
.nfttunz__navbar__search__wrapper input:hover {
  border: 1px solid white;
}

.nfttunz__navbar__search__main__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nfttunz__navbar__search__wrapper button {
  border: none;
  outline: none;
  color: #eee;
}
.nfttunz__navbar__search__wrapper button:hover {
  background: transparent;
}
.nfttunz__navbar__search__wrapper button:focus {
  box-shadow: transparent;
}
.nfttunz__navbar__search__wrapper button i {
  color: rgb(189, 184, 184);
}
.nfttunz__navbar__links__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.nfttunz__navbar__links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nfttunz__navbar__links li a {
  padding: 0 25px !important;
  font-weight: bold;
  font-size: 14px;
}
.auth__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.withdraw__flex__button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.withdraw__flex__button img {
  margin-right: 5px;
}
.withdraw__flex__button span {
  align-self: center;
}
.nav__login__button {
  margin-right: 2px;
}
.nav__register__button {
  margin-left: 2px;
}
.hero__wrapper {
  min-height: 100vh;
  backdrop-filter: blur(3px);
  background-image: url('./assets/images/HERO_BG.svg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.hero__header__text {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.hero__header__text h1 {
  font-size: 72px;
  font-weight: bolder;
  color: #fff;
}
.hero__header__text h1 span {
  background: -webkit-linear-gradient(left, #f3ec78, #af4261);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: gold;
}
.hero__header__text p {
  margin-top: 15px;
  color: #fff;
  font-size: 18px;
}
.hero__header__buttons {
  margin-top: 40px;
}
.hero__header__buttons a {
  background-color: #eee;
  color: #000;
  border: 1px solid #eee;
  padding: 10px 30px;
  outline: none;
  font-size: 18px;
  font-weight: bold;
}
.hero__header__image {
  display: block;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
}
.hero__header__image img {
  width: 95%;
  display: block;
  margin: auto;
}
.hero__header__image p {
  width: 60%;
  text-align: center;
  margin: 15px auto 0px;
  font-size: 13px;
}
.hero__header__image p {
  position: relative;
  color: #ffffff;
}
.hero__header__image p:before {
  content: '';
  background-image: linear-gradient(to right, #f27121cc, #e94057cc, #8a2387cc);
  width: 100%;
  height: 1.6em;
  position: absolute;
  z-index: -1;
  filter: url('./assets/images/market-shape.svg');
  left: -0.25em;
  top: -0.05em;
  padding: 0 0.3em;
}

.hero__stats {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}
.hero__stats__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 60px;
}
.hero__stats__item__text h3 {
  font-size: 30px;
  font-weight: 600;
  border-left: 4px solid #f3ec78;
  padding-left: 10px;
  color: #fff;
}
.hero__stats__item__text span {
  color: #f3ec78;
  font-weight: 600;
}
.hero__stats__item__text p {
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  margin-left: 15px;
}
.nfttunz__avatar__wrapper {
  background-color: #eee;
  border-radius: 50%;
  padding: 6px;
}
.nfttunz__notification__wrapper {
  position: relative;
  background-color: #eee;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 6px;
}
.notification__overflow {
  width: 450px;
  max-height: 88vh;
  overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.notification__overflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification__overflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.top__mark__as__read__button__wrapper {
  padding: 5px 10px;
}
.notification__overflow h6 {
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}
.nfttunz__notification__wrapper button i {
  font-size: 20px !important;
}
.notification__item__each {
  padding: 10px;
  border: 1px solid grey !important;
}
.notification__item__each h4 {
  font-size: 16px;
  margin-bottom: 0px;
}
.notification {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: red;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  font-size: 8px;
  font-weight: 900;
}
.notification-paragraph {
  padding: 0px 15px;
  font-size: 14px;
  box-sizing: border-box;
  min-width: 300px;
  border-bottom: none !important;
  margin-top: 0px;
}
.notification__time {
  font-size: 8px;
  display: block;
}
.mark-as-read-button {
  font-size: 10px;
  font-weight: bolder;
  margin-top: 0px;
}
.footer__social__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}
.footer__social__links {
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
}
.footer__social__icons a i {
  font-size: 20px !important;
  margin: 0px 5px;
}
.footer__social__icons a span {
  font-size: 11px;
}

.nfttunz__avatar__wrapper img {
  border-radius: 50%;
}
.nfttunz__main__header__overlay {
  padding: 100px 0px;
  background-color: rgba(0, 0, 0, 0.652);
  /* height: 100%!important;
  width: 100%; */
}
.nfttunz__artiste__main__header {
  background-image: url('https://images.pexels.com/photos/2111015/pexels-photo-2111015.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  margin-bottom: 50px;
  background-size: cover;
  height: 300px;
}
.nfttunz__main__header {
  background-image: url('https://images.pexels.com/photos/2111015/pexels-photo-2111015.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  margin-bottom: 50px;
  background-size: cover;
}
.nfttunz__main__featured {
  position: relative;
  margin-bottom: 50px;
  background-size: cover;
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.nfttunz__main__feature__overlay {
  /* backdrop-filter: blur(5px); */
  background-color: rgba(0, 0, 0, 0.891);
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__button.text__right {
  text-align: right !important;
}
.feature__header__button div > .feature__round__button :not() .dot__expand {
  text-align: right !important;
}
.feature__header__button.side__round__button .feature__round__button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
  background-color: #eee;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.feature__header__button.side__round__button .feature__round__button i {
  font-size: 16px;
}
button.feature__round__button {
  -webkit-transition: all 1.3s ease-in;
  transition: all 1.3s ease-in;
}
.feature__round__button {
  background-image: linear-gradient(to right, #f27121cc, #e94057cc, #8a2387cc);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0px 10px;
  font-size: 32px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border: 1px solid transparent;
}
.feature__round__button:hover {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0px 10px;
  font-size: 32px;
  color: #f4f4f4;
  transform: translate3d(0, -3px, 0);
  box-shadow: 0px 0px 10px #000000;
  border: 1px solid #5e5e5e;
  z-index: 999;
}
.feature__round__button i {
  font-size: 32px;
  transition: all 0.5s ease-in;
}

/* html:not([data-scroll='0']) .header__button {
  position: fixed;
  bottom: 6%;
  right: 4%;
  z-index: 999999;
  animation: alternate-reverse 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} */
.side__round__button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 6%;
  right: 2%;
  z-index: 999999;
  animation: alternate-reverse 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.playing__info {
  display: none;
  position: relative;
  /* background-color: #a6b0ba; */
  padding: 4px 4px 10px;
  border-radius: 11px;
  box-shadow: 0px 0px 10px #000000;
  transform: translateX(400px);
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.slide__in__left {
  transform: translateX(0);
}
.hide__button__wrapper {
  position: absolute;
  top: -25px;
  right: -20px;
  width: 30px;
  height: 30px;
}
.hide__button__wrapper button {
  outline: none;
  border: 1px solid grey;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  color: #491b19;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.hide__button__wrapper button:hover {
  background-color: gray;
  color: #000;
}
.hide__button__wrapper button i {
  color: #fff !important;
  font-weight: bold;
  font-size: 14px;
}
.playing__info__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.playing__info > img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 11px 11px 0px 0px; /*TL TR BR BL*/
}
.playing__info__player {
  display: flex;
  justify-content: center;
  align-items: center;
}
.playing__info__player__image {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
}
.playing__info__player__name {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}
.playing__info a > p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.playing__info > a > span {
  background-color: unset;
  padding: 0;
  font-weight: bold;
}
.side__round__button > div .playing__info {
  display: block !important;
  margin-bottom: 30px;
}

.nfttunz__feature__audio__player {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
}
.overlay-black {
  background-color: rgba(0, 0, 0, 0.781);
}
.top-overlay {
  position: relative;
  top: 69px;
  margin-top: 0px;
}
.bottom-overlay {
  position: relative;
}
.top-overlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 0.719),
    rgb(0, 0, 0)
  );
  transform: rotate(180deg);
}
.bottom-overlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 17.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 0.719),
    rgb(0, 0, 0)
  );

  /* transform: rotate(180deg); */
}
.nfttunz__profile__header__overlay {
  padding: 50px 0px;
  background-color: rgba(0, 0, 0, 0.56);
  position: relative;
  height: 100%;
}
.nfttunz__profile__header__overlay__2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.56);
  /* z-index: -1; */
}
.profile__header__card {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  padding: 30px;
  padding-bottom: 50px;
  border-radius: 20px;
  margin-bottom: -21px;
  z-index: 3;
  position: relative;
}
.profile__header__card__img__wrapper {
  padding: 8px;
  background: rgba(36, 1, 1, 0.431);
  margin-top: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 155px;
  border-radius: 50%;
}
.profile__header__card__image {
  width: 145px;
  height: 145px;
  border-radius: 50%;
}
.profile__header__info__wrapper {
  margin-top: 10px;
}
.profile__header__info__wrapper h1 {
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.follow__social__icons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
}
.cover__upload__button__wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
}
.rounded_upload__button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee;
  padding: 6px;
  border: 1px solid #eee;
  color: #eee;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
}
.rounded_upload__button:hover {
  background-color: rgb(142, 142, 142);
  color: #eee;
}
.rounded_upload__button i {
  font-size: 20px;
  color: #000;
}
.profile__followers__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.profile__header__followers {
  margin: 10px 8px 0px 0px;
  padding: 0;
  color: #fffefe;
}
.profile__header__followers i {
  font-size: 18px;
  margin: 0px 5px !important;
}
.nfttunz__profile__link button {
  background-color: rgba(238, 238, 238, 0.062);
  border-radius: 15px;
  padding: 6px 12px;
}
.nfttunz__profile__link a {
  padding: 5px 12px;
  border: 1px solid transparent;
  transition: 0.5s all ease-in;
}
.nfttunz__profile__link a:hover {
  cursor: pointer;
  color: wheat !important;
  border: 1px solid wheat;
  border-radius: 25px;
}
.active__link {
  cursor: pointer;
  color: wheat !important;
  border: 1px solid wheat;
  border-radius: 25px;
}
.header__title {
  margin-bottom: 50px;
  color: #fff;
}
.header__title h1 {
  font-size: 50px;
}
.profile__header__title {
  position: relative;
  width: max-content;
  margin: 0 auto;
  margin-bottom: 10px;
}
.profile__header__title h1 {
  font-size: 30px;
  color: #fff;
  margin: 0px;
}
.profile__header__title small a {
  color: #fff;
}
.avatar__upload__button__wrapper {
  position: absolute;
  top: 1px;
  right: 20px;
  z-index: 999;
}
.avatar__rounded_upload__button i {
  color: #000;
}
.avatar__rounded_upload__button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eee;
  padding: 6px;
  border: 1px solid #eee;
  color: #eee;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
}
.avatar__rounded_upload__button:hover {
  background-color: rgb(142, 142, 142);
  color: #eee;
}
.follow__button {
  background-color: rgb(82, 13, 13) !important;
  border-radius: 15px;
  color: #fff !important;
  font-size: 11px !important;
  padding: 6px 12px !important;
  border-radius: 1px solid #fff !important;
  font-weight: bold;
  margin-top: 5px;
}
.follow__button:hover {
  background-color: #fff !important;
  color: rgb(82, 13, 13) !important;
}
.report__button {
  background-color: rgb(82, 13, 13) !important;
  border-radius: 15px;
  color: #fff !important;
  font-size: 8px !important;
  padding: 4px 8px !important;
  border-radius: 1px solid #fff !important;
  font-weight: bold;
  margin-top: 5px;
  border: 1px solid rgb(82, 13, 13) !important;
}
.report__button:hover {
  background-color: #fff !important;
  color: rgb(82, 13, 13) !important;
  border: 1px solid rgb(82, 13, 13) !important;
}
.profile__header__title img {
  margin-bottom: 20px;
  border-radius: 50%;
  border: 2px solid #e9e7e7;
}
.header__button {
  margin-top: 50px;
  -webkit-transition: all 1.3s ease-in-out;
  transition: all 1.3s ease-in-out;
}
.profile__header__button {
  margin-top: 10px;
}
.profile__header__button a {
  background: #5e5e5e;
  padding: 4px 10px;
  text-decoration: none;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  border: 0.4px solid transparent;
  transition: 1s all ease-in;
}
.header__button a {
  background: #5e5e5e;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  border: 0.4px solid transparent;
  transition: 1s all ease-in;
}
.header__button a:hover {
  cursor: pointer;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.section__title {
  margin-bottom: 30px;
}
.section__title h2 {
  font-weight: bold;
  margin: 50px auto;
}
.tag__wrapper {
  display: block;
  font-size: 12px;
  line-height: 25px;
  font-weight: 700;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  color: #5e5e5e;
  padding: 0.4rem;
  padding-right: 0.65rem;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-transition: 0.1s all ease;
  transition: 0.1s all ease;
}
.tags__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tag__wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}
.tag__wrapper a {
  color: #5e5e5e;
  text-decoration: none;
}
.footer__wrapper {
  border-top: 1px solid rgb(51, 51, 51);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
}
.footer__logo {
  display: flex;

  justify-content: center;
  align-items: center;
}
.footer__logo img {
  margin-right: 10px;
}
.footer__copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer__wrapper p,
.footer__wrapper small {
  font-size: 11px;
  color: #000;
  max-width: max-content;
  margin: 0px;
}
.music__card__wrapper {
  position: relative;
  border-radius: 2px;
  margin: 10px 0;
  padding: 0px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  /* min-height: 290px; */
  /* max-height: 290px; */
}

.music__card__thumbnail {
  margin-bottom: 0px;
  padding: 0px 5px;
  padding-bottom: 0px;
  overflow: hidden;
}
.music__card__thumbnail img:hover {
  transform: scale(1.3);
}
.music__card__thumbnail img {
  width: 100%;
  height: 15.5em;
  max-height: auto;
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
}
/* Loading for card images */
.target__skeleton > svg {
  backdrop-filter: blur(8px);
  opacity: 0.2;
}

.blur__image__loader {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}
.music__card__editions__wrapper {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  z-index: 999;
}
.music__card__editions__wrapper span {
  font-size: 14px;
  font-weight: bolder;
}
.music__card__title {
  margin: 0px 0px 10px;
  padding: 5px;
  background-image: linear-gradient(
    87.65deg,
    rgb(234, 209, 168) -1.34%,
    rgba(234, 209, 168, 0.2) 73.33%,
    rgb(234, 209, 168) 104.02%
  );
}
.music__card__title h4 {
  font-size: 15px;
  font-weight: 700;
  margin: 0px 0px 2px 0px;
  text-transform: uppercase;
}
.music__card__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  background-color: rgba(62, 255, 4, 0.027);
}
.music__card__artiste {
  align-self: flex-start;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
}
.music__card__artiste__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.music__card__artiste__details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 5px;
  margin-top: 8px;
}
.music__card__artiste small {
  font-size: 0.6rem;
}
.music__card__artiste small i {
  color: rgb(213, 228, 233);
  font-size: 14px;
}
.music__card__artiste a {
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
}
.music__card__extras {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  margin: 0px 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  width: 90%;
}
.music__card__extras h6 {
  font-size: 12px;
  margin-right: 10px;
  color: #abaaaa !important;
  align-self: flex-end;
}
.music__card__extras h6 i {
  color: #5e5e5e;
  text-decoration: none;
}
.music__card__price__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5px;
  align-self: flex-end;
  margin-left: auto;
}
.music__card__price__wrapper h4 {
  font-size: 19px;
  font-weight: bolder;
  margin: 0px 0px 0px 8px;
  color: #5e5e5e;
}
.music__card__price__wrapper h4 img {
  margin-right: 4px;
}
.music__card__price__wrapper p {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 10px;
}
.play__button {
  margin: 5px 0px;
  display: flex;
  justify-content: center;
}
.play__button button {
  padding: 2px 8px;
  background: #000;
  color: #fff;
  font-size: 10px;
  border-radius: 8px;
  border: 0.4px solid transparent;
  transition: 1s all ease-in;
}
.round__button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0px 10px;
}
.play__button button:hover {
  cursor: pointer;
  background: transparent;
  color: #000;
  border: 0.4px solid #000;
}
.play__button a {
  padding: 2px 8px;
  border: 0.4px solid #000;
  color: #000;
  font-size: 10px;
  border-radius: 8px;
  margin-left: 3px;
  transition: 1s all ease-in;
}
.play__button a:hover {
  cursor: pointer;
  background: #000;
  color: #fff;
  border: 0.4px solid #fff;
}
.info__text__wrapper {
  padding: 20px;
  background-color: rgba(144, 238, 144, 0.26);
  border: 2px solid gray;
}
.info__text {
  font-weight: bold;
}
.earning__card__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0;
  padding: 5px 20px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
}
.earnings__details__card__wrapper {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 2fr;
  -ms-grid-template-columns: 2fr 3fr 1fr 3fr;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin: 5px 0;
  padding: 5px 20px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
}
.earning__card__wrapper.top {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
}
.earning__card__wrapper.top > h4 {
  font-weight: bold;
  color: wheat;
}
.earning__card__wrapper.top p {
  font-size: 22px;
}
.earning__card__wrapper.top span {
  font-weight: bold;
  font-size: 11px;
  color: wheat;
}
.earning__card__thumbnail__wrapper {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.earning__card__thumbnail {
  width: 100%;
  /* height: 60px; */
  max-height: 60px;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  aspect-ratio: 16 / 9;
}
.earning__card__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 11px;
  width: auto;
}
.earning__card__item.collection__name {
  font-weight: bold;
  color: wheat;
  width: 20%;
  align-items: flex-start;
}
.earning__card__item.earning__price {
  width: 25% !important;
}
.earning__card__item strong {
  color: wheat;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.earning__card__item span {
  font-size: 11px;
  font-weight: bold;
}
.artiste__details {
  width: 10%;
}
.artiste__details span {
  font-size: small;
  display: block;
}
.collection__name {
  width: 20%;
}
.collectible__tag {
  background: whitesmoke;
  border-radius: 8px;
  border-radius: 1px solid white;
  font-size: 10px;
  color: #000;
  padding: 2px 8px;
  margin: 0px 4px 0px 0px;
}
.market__collectible__title {
  font-weight: bolder;
  font-size: 56px;
}
.market__screen__card {
  margin-top: 30px;
  padding: 20px;
  border-radius: 8px;
}
.market__sellbook__height {
  max-height: 710px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.market__sellbook__height tr > th {
  border-bottom: 4px solid rgba(236, 236, 236, 0.609);
  width: max-content;
}
.market__sellbook__height tr > td {
  padding: 10px;
  border-bottom: 4px solid rgba(236, 236, 236, 0.609);
  vertical-align: middle;
}
.market__creator__info {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  width: max-content;
  cursor: pointer;
  text-transform: uppercase;
}

.created__by {
  padding: 0px 8px;
  font-size: 12px;
  margin-bottom: 5px;
  cursor: pointer;
  -o-transition: display 0.5 ease;
  -moz-transition: display 0.5 ease;
  -webkit-transition: display 0.5 ease;
  transition: display 0.5 ease;
}
.creator__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.creator__info__dialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.creator__dialog {
  position: absolute;
  min-width: 300px;
  max-width: 400px;
  bottom: -130px;
  left: 0px;
  opacity: 0;
  padding: 20px;
  transform: translateX(-50%);
  z-index: -1;
  border-radius: 10px;
  -moz-transition: all 0.6s ease-in; /* Firefox 4 */
  -webkit-transition: all 0.6s ease-in; /* Safari and Chrome */
  -o-transition: all 0.6s ease-in; /* Opera */
  -ms-transition: all 0.6s ease-in; /* Explorer 10 */
  transition: all 0.6s ease-in;
}
.creator__info:hover .creator__dialog {
  opacity: 1;
  transform: translateX(0);
  cursor: pointer;
  z-index: 1;
}
/* .creator__dialog h1 {
  font-size: 14px;
  -o-transition: display 0.5 ease;;
  -moz-transition: display 0.5 ease;;
  -webkit-transition: display 0.5 ease;;
  transition: display 0.5 ease;
} */
.followed__by__wrapper strong {
  margin-right: 8px;
}
.following__wrapper img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: -5px;
}
.market__creator__info {
  display: flex;
}
.market__creator__info img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.market__creator__info p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}
.market__follow__button {
  font-size: 14px !important;
  padding: 2px 18px !important;
  align-self: center;
  background-color: transparent;
  border: 1px solid #520d0d !important;
}
.social_share__item {
  margin: 0px 5px 0px 0px;
}
.application_message {
  font-size: 8px;
  background-color: lightyellow;
  color: black;
  font-weight: 600;
  border-radius: 5px;
  padding: 3px;
}
.mint__form__wrapper {
  margin-top: 50px;
  padding: 20px;
  /* border: 2px solid gray; */
  background: rgb(247, 255, 247);
  margin-bottom: 50px;
  box-shadow: -1px 2px 14px 1px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: -1px 2px 14px 1px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: -1px 2px 14px 1px rgba(0, 0, 0, 0.42);
}
.form__wrapper strong {
  margin: 10px 0px;
  display: inline-block;
}
.double__input__row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.credits__double__input__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.double__input__row input {
  border: none;
  background-color: #eeeeee;
  width: 100%;
}
.credits__double__input__row input {
  border: none;
  background-color: #eeeeee;
  width: 60%;
  display: block;
  margin: 10px auto;
  border: green 1px solid;
}
.credits__double__input__row input::hover,
.credits__double__input__row input::active,
.credits__double__input__row input::focus,
.credits__double__input__row input::focus-visible {
  border: 1px solid grey;
}
.nfttunz__input__border {
  border: 1px solid green;
  margin: 5px;
}
.nfttunz__filter__input__border {
  border: 1px solid transparent;
  border-radius: 25px;
  margin: 5px;
}
.nfttunz__filter__input {
  border-radius: 25px !important;
}
.filter__button {
  background: #582262 !important;
  border: 1px solid #343333 !important;
  transition: all 0.5s ease-in-out;
}
.filter__button[value] {
  color: #fff !important;
  font-weight: bold;
}
.filter__button:hover {
  background: #64256f !important;
  border: 1px solid #b8b2b2 !important;
}
.nfttunz__credits__input__wrapper {
  display: flex;
  justify-content: space-between;
}
.nfttunz__credits__input__wrapper button {
  padding: 0px 20px;
}
.nfttunz__credits__input__wrapper > .credit__units {
  margin: 5px;
}
.credit__units {
  padding: 0.075rem;
}
.credit__units h4 {
  color: rgb(34, 33, 33);
}
.nfttunz__filter__select {
  border-radius: 25px;
  padding: 6px 12px;
  border: 1px solid transparent;
  background-color: #eeeeee;
}
.nfttunz__file__wrapper {
  border: 1px solid green;
  margin: 5px;
  background-color: #eeeeee;
  padding: 30px 0px;
  min-height: 166px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nfttunz__textarea {
  background-color: #eeeeee;
  height: 100%;
  padding: 6px 12px;
  resize: none;
}
.nfttunz__textarea:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.nfttunz__select {
  padding: 6px 12px;
  background-color: #eeeeee;
}
.nfttunz__select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.nfttunz__checkbox__wrapper {
  display: flex;
  padding: 6px 12px;
}
.nfttunz__checkbox__wrapper input {
  width: auto;
  margin-right: 10px;
}
.checkbox__label.small__text {
  font-size: 10px !important;
  font-weight: bold;
}
.checkout__button {
  padding: 10px 25px;
  background-image: linear-gradient(to right, #582262, #64256f);
  border-radius: 8px;
  color: orange;
  transition: all 0.5s ease-in-out;
  font-weight: bold;
}
.checkout__button:hover {
  background-image: linear-gradient(to right, #64256f, #582262);
  color: #fff;
}
.upload__button {
  background-color: #aa0c0b;
  color: wheat;
  font-size: 9px;
  border-radius: 8px;
  padding: 5px 12px;
  margin: 10px 0px;
}
.upload__button:hover {
  cursor: pointer;
}
.steps__input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}
.button__and__loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.thumbnail__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16 / 9;
  z-index: 1;
}
.player__wrapper__height {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 10px 0px 30px;
  margin: 50px 0px;
}
.file__player {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  /* width: min-content; */
  padding: 5px;
  /* margin: auto; */
}
.player__height__size {
  height: 100%;
}
.audio__thumbnail {
  position: relative;
  display: flex;
  box-sizing: content-box;
  border-width: 6px;
  border-style: solid;
  border-image: linear-gradient(to right, #f271218b, #e94057cc, #8a2387cc);
  border-image-slice: 1;
}
.market__audio__thumbnail {
  height: 85%;
}
.market__audio__thumbnail__fan {
  height: 100%;
}
.market__audio__thumbnail img {
  max-height: 100% !important;
}
.market__audio__thumbnail__fan img {
  max-height: 100% !important;
}
.audio__thumbnail__blurred__background {
  /* background-image: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;
  /* border: 5px solid rgb(92, 3, 3); */
  /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}
.audio__thumbnail__blurred__overlay {
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.15); */
  width: 100%;
  height: 100%;
  z-index: -1;
  /* margin: -5px; */
  backdrop-filter: blur(5px);
}

.audio__thumbnail img {
  object-fit: contain !important;
  width: 90%;
  height: auto;
  max-height: 400px;
  object-position: center;
  aspect-ratio: 16 / 9;
  z-index: 9;
  margin: auto;
  /* padding: 10px; */
}
.nfttunz__profile__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 30px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  /* z-index: 9999; */
  color: wheat !important;
  z-index: 4 !important;
}

.nfttunz__profile__links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.nfttunz__profile__link {
  padding: 0px 10px;
  font-weight: bold;
  font-size: 12px;
}
.profile__header__icons a {
  color: #fff;
  padding: 0px 5px;
}

.sub__profile__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile__card {
  margin-top: 30px;
  padding: 40px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: 0.5s all ease-in-out;
  border: 4px solid transparent;
}
.profile__card h4 {
  font-size: 16px;
}

.arrow__wrapper {
  padding: 10px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  background-color: #fff;
}
.left__ {
  top: 20%;
  left: -3.5%;
}
.right__ {
  top: 20%;
  right: -3.5%;
}
.hide__sm {
  display: none;
}

/* Style the input fields */
.preview__card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 4px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: 0.5s all ease-in-out;
  border: 4px solid transparent;
}
.preview__card h6 {
  margin-top: 10px;
}
.preview__card a {
  display: block;
  margin: auto;
  margin-top: 10px;
}
.steps__input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
  margin: 4px 0px;
}
.step__button__wrapper {
  margin-top: 15px;
  text-align: right;
}
.step__button__wrapper button {
  background-color: #491b19;
  color: #fff;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 4px;
  margin-right: 5px;
  border: none;
  outline: none;
}
.double__input__per__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.double__input__per__row input:first-child {
  margin-right: 5px;
}
.double__input__per__row select:first-child {
  margin-right: 5px;
}
.add__more__input__button {
  background-color: transparent;
  color: #491b19;
  border: none;
  outline: none;
  padding: 8px 14px;
  font-size: 11px;
  display: flex;
  text-decoration: underline;
}
.add__more__input__button i {
  align-self: center;
  margin-left: 5px;
}
.sub__header__with__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
textarea {
  resize: none;
}

input:focus-visible,
select:focus-visible {
  box-shadow: none;
  background-color: #fff;
  /* border: none; */
  outline: none;
}
.auth__button {
  width: 100%;
  background-color: #aa0c0b;
  color: #fff;
  font-weight: 700;
  border: 1px solid #aa0c0b;
  outline: none;
  padding: 8px;
  transition: 0.5s all ease-in-out;
}

.auth__button:hover {
  background-color: #fff;
  color: #aa0c0b;
}

.nfttunz__search__wrapper {
  background-color: #eee;
  display: flex;
  border-radius: 25px;
  width: 50%;
  margin: 40px auto 20px;
  padding: 5px;
}
.nfttunz__search__wrapper input {
  background-color: transparent;
  border: none;
}

.nfttunz__search__main__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nfttunz__search__wrapper button {
  border: none;
  outline: none;
  color: #eee;
}
.nfttunz__search__wrapper button:hover {
  background: transparent;
}
.nfttunz__search__wrapper button:focus {
  box-shadow: transparent;
}
.nfttunz__search__wrapper button i {
  color: rgb(189, 184, 184);
}
.activity__card__wrapper {
  display: flex;
  margin-bottom: 20px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
}
.activity__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 6;
  margin-right: 20px;
}
.activity__image .img {
  width: 100%;
  max-height: 160px;
  object-fit: contain;
}
.activity__desc {
  flex: 10;
  padding: 10px;
}
.table {
  font-size: small;
}
.nfttunz__table td {
  vertical-align: middle;
  font-size: small;
  border: none !important;
}
.nfttunz__table th {
  vertical-align: middle;
  border: none !important;
}
.market__list__card {
  border-radius: 8px;
}
.nfttunz__subscribe__button {
  width: 25%;
  font-weight: bold;
  background-color: #212529;
  color: #eee;
}
.nfttunz__subscribe__button a {
  color: #eee;
  background-color: #aa0c0b;
  padding: 15px 25px;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}
.nfttunz__subscribe__button a:hover {
  background-color: #eee;
  color: #aa0c0b;
}
.nfttunz__modal__dialog {
  margin: auto !important;
  width: 1200px !important;
  max-width: 1200px !important;
  height: 80vh !important;
}
.nfttunz__modal__content {
  width: 100%;
  height: 90vh;
}
.player-modal i {
  font-size: 22px;
}
.modal {
  -webkit-transition: all 10.3s ease-in-out;
  -moz-transition: all 10.3s ease-in-out;
  transition: all 10.3s ease-in-out;
}
.modal__audio__thumbnail {
  height: 67vh;
}
.modal__audio__thumbnail .fan {
  height: 90vh;
}
.fan__thumbnail {
  height: 80vh !important;
  max-height: 77vh !important;
  object-fit: cover !important;
  object-position: center;
}
.modal__audio__thumbnail img {
  object-fit: cover !important;
  width: 100%;
  height: 67vh;
  max-height: 67vh;
  object-position: center;
  aspect-ratio: 16 / 9;
  /* height: 67vh!important; */
}
.collectible__description {
  font-size: 14px;
  line-height: 1.5;
}
.collectible__descriptive__text__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0px 40px;
}
.collectible__descriptive__text {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 12px;
}
.collectible__descriptive__text__each__wrapper {
  box-sizing: content-box;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #fffffe8b, #ffacb7cc, #ffd7fecc);
  border-image-slice: 1;
  background-color: transparent;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  /* border-radius: 4px; */
}
.collectible__descriptive__text.group__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000059;
  backdrop-filter: blur(10px);
  padding: 4px 20px;
  margin: 0;
  font-size: 12px;
}
.group__text.start__align {
  align-items: flex-start;
  justify-content: flex-start;
}
.group__text strong:first-child {
  font-size: 12px;
  font-weight: 600;
}
.group__text strong:nth-child(2) {
  font-size: 11px;
  font-weight: 300;
}
.player__wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999999;
  display: none;
}
.player__description {
  font-size: 10px;
}

/* Utilities */
@media only screen and (min-width: 1024px) {
  .hide__desktop {
    display: none;
  }
  .market__sellbook__height .nft__table__content {
    min-height: unset !important;
  }
  .nft__table__content {
    max-height: 550px;
  }
}
/* TV */
@media only screen and (min-width: 1900px) {
  .nfttunz__navbar__brand {
    width: auto;
  }
  .hide__desktop {
    display: none;
  }
  .hero__header__text {
    margin-top: 250px;
  }
  .hero__header__image {
    margin-top: 250px;
  }
  .hero__header__image img {
    width: 60%;
  }
  .hero__header__buttons {
    margin-top: 50px;
  }
  .hero__stats {
    margin-top: 100px;
  }
}
/* Medium */
@media only screen and (max-width: 1400px) and (min-width: 1350) {
  .hero__wrapper {
    height: unset;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .nfttunz__navbar__brand {
    width: auto;
  }
  .hide__desktop {
    display: none;
  }
  .nft__table__row__item {
    font-size: 8px;
  }
  .nft__buy__button,
  .nft__bid__button,
  .nft__add__cart__button,
  .nft__del__cart__button {
    font-size: 8px !important;
    padding: 2px 5px !important;
  }
  .nft__table__row__item.nft__table__market.nft__table__market__price:nth-child(
      4
    ) {
    width: 30% !important;
  }
  .nft__table__row__item.nft__table__market.nft__table__market__actions:nth-child(
      6
    ) {
    width: 18% !important;
    padding-left: 5px !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .nfttunz__navbar__brand {
    width: auto;
  }
  .hide__mobile {
    display: none;
  }
  .tag__wrapper img {
    width: 18px;
    height: 18px;
  }
  .password__container {
    font-size: 11px !important;
  }
  .nft__table__row__item.nft__table__market.nft__table__market__series:nth-child(
      2
    ) {
    display: none;
  }
}
/* Mobile Stuff */
@media only screen and (max-width: 600px) and (min-width: 360px) {
  .hide__mobile {
    display: none;
  }
  .hero__wrapper {
    padding: 0px 20px;
  }
  .hero__header__text {
    margin-top: 150px;
  }
  .hero__header__text h1 {
    font-size: 3rem;
  }
  .hero__stats__item__text h3 {
    font-size: 20px;
  }
  .hero__stats__item__text p {
    font-size: 11px;
  }
  .hero__header__image {
    display: none;
  }
  .unset__flex__mobile {
    display: unset !important;
    width: 100%;
  }
  .side__round__button > div .playing__info {
    display: none !important;
  }
  .nfttunz__navbar__search__wrapper {
    margin: 10px auto;
    padding: 4px;
    font-size: 14px;
  }
  .mobile__flex__col {
    justify-content: space-between;
    /* height: 100vh; */
    /* position: fixed; */
    width: 100%;
    /* background-color: tomato; */
    color: thistle;
    /* z-index: 9999999; */
  }
  .nfttunz__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 100vh; */
  }
  .nfttunz__navbar__links {
    display: flex;
    flex-direction: column !important;
  }
  .nfttunz__navbar__brand {
    width: auto;
    margin: 10px 0 0;
  }
  .nfttunz__navbar__links > li {
    margin-bottom: 30px;
  }
  .nfttunz__navbar__links > li a {
    font-size: 19px !important;
  }
  .flex__mobile {
    display: flex;
    align-items: center;
    width: 100%;
    color: #141625;
  }
  .flex__mobile > span > i {
    color: #000000;
  }
  .navbar-toggler-icon i {
    margin: auto;
    font-size: 28px;
  }
  .music__card__wrapper {
    margin: 0px 10px;
    height: auto;
    /* min-height: 500px; */
    justify-content: space-around;
  }
  .music__card__thumbnail {
    width: 100%;
    margin-bottom: 5px;
  }
  .music__card__thumbnail img {
    height: 20em;
    min-height: 20em;
    width: 100%;
  }
  .music__card__artiste small {
    font-size: 18px !important;
  }
  .music__card__artiste a {
    font-size: 18px !important;
  }
  .music__card__editions__wrapper {
    width: 40px;
    height: 40px;
  }
  .music__card__editions__wrapper span {
    font-size: 10px;
  }
  .music__card__editions__wrapper small {
    font-size: 6px !important;
  }
  .explore__header {
    text-align: center;
    font-weight: bolder;
    font-size: 30px;
    margin: 20px auto;
  }
  .play__button {
    /* flex-direction: column; */
    align-items: center;
  }
  .play__button button {
    font-size: 18px;
  }
  .play__button > a {
    font-size: 18px !important;
    text-align: center;
  }
  .nfttunz__navbar {
    flex-direction: column;
  }
  .nfttunz__navbar__links__wrapper {
    margin: 15px 0px;
  }
  .nfttunz__navbar__search__main__wrapper {
    flex-direction: column;
    margin: 0px 0px 15px;
  }
  .nfttunz__navbar__search__main__wrapper button {
    display: block;
    margin-top: 10px;
  }
  .tags__main__wrapper {
    display: flex;
  }
  .tags__wrapper {
    display: block;
    text-align: center;
  }
  .tag__wrapper {
    margin-bottom: 10px;
  }
  .nfttunz__avatar__wrapper {
    margin-top: 8px;
    display: none;
  }
  .password__container {
    font-size: 11px !important;
  }
  .player__height__size {
    width: 100%;
  }
  .market__audio__thumbnail img {
    height: 100%;
    object-fit: cover !important;
    object-position: center;
  }
  .market__collectible__title {
    font-size: 40px;
  }
  .player__wrapper__height {
    height: 50vh;
    width: 99%;
  }
  .nfttunz__countup__wrapper {
    margin-bottom: 40px !important;
  }
  .music__card__wrapper {
    width: 80%;
    margin: 0px auto;
  }
  .music__card__title > a > h4 {
    font-size: 18px;
  }
  .footer__wrapper {
    border-top: 1px solid rgb(51, 51, 51);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0px;
  }
  .footer__social__links {
    margin: 10px 0px;
  }
  .nft__table__row__item {
    font-size: 8px;
  }
  .nft__buy__button,
  .nft__bid__button,
  .nft__add__cart__button,
  .nft__del__cart__button {
    font-size: 8px !important;
    padding: 2px 5px !important;
  }
  .nft__table__row__item.nft__table__market.nft__table__market__price:nth-child(
      4
    ) {
    width: 30% !important;
  }
  .nft__table__row__item.nft__table__market.nft__table__market__actions:nth-child(
      6
    ) {
    width: 18% !important;
    padding-left: 5px !important;
  }
  .nft__table__row__item.nft__table__market.nft__table__market__series:nth-child(
      2
    ) {
    display: none;
  }
}

.admin-container {
  display: flex;
}
.admin-right-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: center; */
  /* align-items: center; */
}
.admin-nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eeeeee;
  margin: 0px 10px;
  padding: 10px;
}
.admin-nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.admin-dashboard-card {
  background: #eeeeee;
  padding: 10px;
  margin: 10px 10px 0px;
}
.admin-search-wrapper {
  border: 2px solid rgb(43, 43, 43);
  padding: 5px 10px;
  margin: 0;
  color: rgb(32, 32, 32);
  border-radius: 20px;
}
.admin-search {
  background: transparent;
  border: none;
  outline: none;
}
.admin-search-icon {
  background: transparent;
  border: none;
  outline: none;
  color: rgb(43, 43, 43);
}
.brand-name h2 {
  font-size: 18px;
  margin: 0px;
}
.side-menu-links {
  margin: 0;
  padding: 0;
}
.side-menu {
  /* position: fixed; */
  background-color: #fffbfb;
  width: 22vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.side-menu .brand-name {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-menu li {
  font-size: 18px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  color: rgb(43, 43, 43);
  font-weight: bold;
  transition: 1s all ease;
}
.side-menu li:hover {
  background-color: rgb(43, 43, 43);
  color: #f05462;
}
.side-menu li:hover a {
  color: #f05462;
}
.side-menu li a {
  color: rgb(43, 43, 43);
  text-decoration: none;
}
.admin__report__card {
  background-color: #fffbfb;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}
.cart__header__wrapper {
  margin-bottom: 30px;
  padding: 20px 0px;
  margin-top: 20px;
}
.cart__header__title {
  font-weight: 700;
}
.cart__header__wrapper p {
  font-size: 14px;
}
.cart__item__text__wrapper p {
  font-size: 14px;
}
.cart__item__card {
  border: 1px rgba(211, 211, 211, 0.273) solid;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 5px;
}
.cart__item__card__left {
  min-height: 400px;
  border: 1px rgba(211, 211, 211, 0.273) solid;
  padding: 10px;
}
.empty__cart__info__wrapper h4 {
  font-size: 18px;
  font-weight: 700;
}
.empty__cart__info__wrapper p {
  font-size: 14px;
  font-weight: 500;
}
.cart__image__preview {
  width: 100% !important;
  height: auto;
}
.cart__inner__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
}
.cart__item__title {
  margin-bottom: 10px;
}
.cart__item__title h2 {
  font-size: 18px;
  font-weight: bolder;
}
.cart__item__body {
  font-size: 14px;
  margin-bottom: 10px;
}
.cart__item__features {
  margin: 0;
  list-style: square;
  font-size: 14px;
}
.empty__cart__info__wrapper {
  padding: 20px;
}
.empty__cart__info__wrapper h4 {
  font-size: 20px;
  color: #491b19;
}
.empty__cart__info__wrapper p {
  font-size: 16px;
}
.cart__preview__title {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 700;
}

.image__loader {
  background-color: red;
  width: 100px;
  height: 100px;
  z-index: 888;
}
.card__image {
  -webkit-transition: 1s all ease-in-out;
  -moz-transition: 1s all ease-in-out;
  -o-transition: 1s all ease-in-out;
  transition: 1s all ease-in-out;
}
.not__auth__button__wrapper button {
  padding: 6px 15px;
  border-radius: 25px;
  font-size: 18px;
}
.wallet__card__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 4px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: 0.5s all ease-in-out;
  border: 4px solid transparent;
}
.wallet__card__wrapper.min__height__220 {
  min-height: 250px;
  max-height: 250px;
  justify-content: space-between;
}
.wallet__cards {
  border: 1px solid #e0e0e052;
  padding: 10px;
  margin: 10px auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wallet__cards h6 {
  font-size: 14px;
  margin: 0px;
}
.wallet__info {
  font-size: 11px;
  color: red;
}
.mini__balances {
  display: flex;
  justify-content: space-between;
}
.followers__list {
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 400px !important;
}
.each__follow a {
  color: wheat !important;
  font-weight: bold;
}
.buy__with__crypto__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  border: 1px solid lightgray;
  margin: auto;
  width: 100%;
}
.crypto__select {
  padding: 12px 10px;
  border: none;
  margin: 0;
  font-size: 11px;
}
.buy__with__crypto__button {
  padding: 12px 10px;
  background: #ffe2e2;
  color: #000;
  font-size: 11px;
  display: block;
  width: min-content;
  white-space: nowrap;
  border-radius: 0px;
  transition: all 0.5s ease-in-out;
}
.buy__with__crypto__button:hover {
  padding: 12px 10px;
  background: #000;
  color: #fff;
  font-size: 11px;
  display: block;
  width: min-content;
  white-space: nowrap;
  border-radius: 0px;
}
.confirm__buy__with__crypto__button {
  padding: 6px 10px;
  background: #aa0c0b;
  color: wheat;
  font-size: 11px;
  display: block;
  width: min-content;
  white-space: nowrap;
  border-radius: 5px;
  transition: 1s all ease-in;
  font-weight: bold;
}
.confirm__buy__with__crypto__button:hover {
  padding: 6px 10px;
  color: #aa0c0b;
  background: wheat;
}
.payment__info__card {
  background-color: #fffbfb;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.payment__info__card__each__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;
}
.payment__info__card__each__item h6 {
  font-size: 12px;
  color: rgb(32, 31, 31) !important;
  margin-bottom: 0px;
  font-weight: bold;
}
.payment__info__card__each__item p {
  font-size: 12px;
  color: rgb(32, 31, 31);
}
.payment__info__text {
  font-size: 10px;
  color: rgb(32, 31, 31);
  margin-bottom: 10px;
  text-align: center;
  line-height: normal;
}
.qr__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.manual__entry {
  font-size: 9px;
  font-weight: bold;
  color: rgb(37, 10, 10);
  border-radius: 8px;
  margin-top: 15px;
}
.share__button {
  background-color: red !important;
  color: #fff;
  font-size: 11px;
  border-radius: 8px;
}
.register__card__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 4px 40px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: 0.5s all ease-in-out;
  border: 4px solid transparent;
}
.register__form label {
  font-size: 14px;
  font-weight: bold;
}
.register__form input {
  background-color: #212529;
  padding: 10px;
  border-radius: 8px;
  color: #5e5e5e;
  border: 1px solid #5e5e5e;
}
.register__form input:focus-visible {
  background-color: #1d1c1c;
  box-shadow: none;
  color: #5e5e5e;
}
.register__form input:focus-within {
  background-color: #2a2d30;
}
.form-control:disabled {
  background-color: #2a2d30;
  color: green;
}
.password__container {
  padding: 10px;
  background-color: #212529;
  border-radius: 8px;
  border: 1px solid #5e5e5e;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.password__container:hover {
  cursor: pointer;
}
.password__container span {
  font-size: 14px;
}
.password__container i {
  color: #bee62f;
  font-size: 16px;
}
.register__info__text {
  font-size: 10px;
  color: yello;
}
.register__button {
  margin-top: 10px;
  display: block;
}
.register__loading__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000009f;
  z-index: 99;
}

.register__loading__container img {
  width: 50px !important;
}
.register__info__container {
  background-color: #7bff0010;
  padding: 15px 30px;
  border: 1px solid rgba(255, 255, 0, 0.329);
  border-radius: 8px;
  margin-bottom: 14px;
}
.register__fee {
  font-weight: bold;
  color: rgba(255, 255, 0, 0.459);
  font-size: 14px;
  margin-bottom: 5px;
}
.register__info__text {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0;
}
.nfttunz__stats__header {
  font-weight: bolder;
  font-size: 44px;
  margin: 60px 0px 40px;
}
.nfttunz__countup__wrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.nfttunz__countup__wrapper h4 {
  color: #000;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  margin: 0;
}
.nfttunz__countup__wrapper > span {
  font-size: 44px;
  font-weight: bold;
  color: rgb(48, 44, 44);
}
.animate__scrolling {
  width: calc(20rem * 8);
  display: grid;
  grid-template-columns: repeat(8, 20rem);
  grid-column-gap: 2rem;
  backface-visibility: hidden;
  animation: advert-translate 18s infinite;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(1, 1, 1, 1);
  cursor: pointer;
}
.animate__scrolling.glide__right {
  width: calc(20rem * 8);
  display: grid;
  grid-template-columns: repeat(8, 20rem);
  grid-column-gap: 2rem;
  backface-visibility: hidden;
  animation: inverse-translate 28s infinite;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(1, 1, 1, 1);
  cursor: pointer;
}
.sellers__card {
  display: flex;
  align-items: center;
  width: 100% !important;
  min-height: 66px;
  flex-grow: 1;
  justify-content: center;
  background-image: linear-gradient(to right, #200325, #26022c);
  /* background-color: var(--bg-light); */
  color: var(--color-dark);
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 0.375rem 0.9375rem -4px rgb(0 0 0 / 20%);
  transition: all 0.25s ease-in-out;
  margin-bottom: 10px;
  transition: transform 0.5s;
  z-index: 99;
}
.animate__scrolling:hover {
  animation-play-state: paused;
}
.animate__scrolling .sellers__cardr:hover {
  transform: scale(0.95);
}
.sellers__card__img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}
.sellers__card__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 10px;
  /* align-items: center; */
}
.sellers__card__info h3 {
  font-size: 0.7rem;
  margin-bottom: 0;
  font-weight: bold;
}
.sellers__card__info h4 {
  font-size: 0.5rem;
  margin-bottom: 0;
}
.sellers__card__info p {
  font-size: 0.5rem;
  margin-bottom: 0;
}

.feature__round__button.dot__expand {
  animation: dot-expander-3d 1.5s infinite; /* remove 'infinate' if you just want 1 cycle */
  /* color: #fff!important; */
}

/* Animations */
@keyframes dot-expander {
  0% {
    background: rgb(0, 0, 0);
    transform: scale(1);
  }

  50% {
    background: rgb(2, 45, 2);
    transform: scale(1.5);
  }

  100% {
    background: rgb(0, 0, 0);
    transform: scale(1);
  }
}

@keyframes dot-expander-3d {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.35, 1.35, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes advert-translate {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(calc(20rem * -4));
  }
}
@keyframes inverse-translate {
  100% {
    transform: translateX(0%);
  }

  0% {
    transform: translateX(calc(20rem * -4));
  }
}

/* Awesome Modal */
.modal-parent > div > div > div:nth-child(1) {
  background-image: linear-gradient(to right top, #000101, #031211);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0.5rem 1rem -4px rgb(0 0 0 / 20%);
  backdrop-filter: blur(10px);
}
.modal__input {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  font-weight: 500;
}
.modal-parent > div > div > div:nth-child(2) {
  backdrop-filter: blur(3px);
  /* background-color: red; */
}
.modal__header {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.modal__header h3 {
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}
.modal__body {
  padding: 20px 0px;
}
.modal__body p {
  font-size: 14px;
  margin-bottom: 0;
}
.modal__body p small {
  font-size: 14px;
  font-weight: bold;
}
.modal__input__currency__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}
.modal__input__currency__wrapper input {
  border: none !important;
  font-weight: bold;
  font-size: 18px;
}
.modal__input__currency__wrapper .modal__currency {
  background-image: linear-gradient(to right top, #000101, #031211);
  padding: 8px 10px;
  font-size: 10px !important;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.modal__currency__estimate {
  font-size: 10px !important;
  padding: 0px 12px;
  font-weight: bold;
}
.modal__footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modal__button {
  background: #141625;
  color: #fff;
  padding: 10px 15px;
  font-size: 12px;
}
.modal__button:nth-of-type(1) {
  margin-right: 10px;
  background: #570101;
}
.modal__button[disabled] {
  background: rgb(148, 148, 148);
  cursor: not-allowed;
  color: #000;
}
.modal__body > div select {
  background-color: transparent;
  color: #ccc;
  border: none;
  padding: 10px 5px;
}
.modal__body > div {
  margin-bottom: 10px;
}
.modal__body > div input {
  background-color: transparent;
}

.modal__manual__button {
  background: #141625;
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 10px;
  display: inline-block;
}
.modal__text__button {
  font-size: 12px;
  outline: none;
  background-color: transparent;
  text-decoration: underline;
  margin: auto;
  display: block;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  border-bottom: 1px solid #ccc;
}

input,
input:hover,
input:focus,
input:active {
  box-shadow: none !important;
  outline: none;
  border-bottom: 1px solid #ccc;
}

.nfttunz__faq {
  background-color: #00050e;
  padding: 20px 0px 60px;
}

.accordion {
  max-width: 700px;
  margin: 2rem auto;
}
.accordion-each-item {
  border: 1px solid #343333;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #26102a,
    #27102b,
    #28112d,
    #2a112e,
    #2b1230
  );
  transition: all 0.5s ease-in;
}

.accordion-title:hover {
  background-image: linear-gradient(to right, #26102a, #582262);
}
.accordion-title h6 {
  font-weight: bold;
  text-transform: uppercase;
  color: #ead675;
  margin-bottom: 0px;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}
.accordion-title i {
  color: #ead675;
}
.accordion-content {
  background-image: radial-gradient(
    circle,
    #010711,
    #080c1a,
    #110f21,
    #1b1127,
    #26102a
  );
  opacity: 0;
  transition: all 0.5s ease-out;
}
.accordion-hide {
  padding: 0;
  height: 0;
  overflow: hidden;
}
.accordion-show {
  height: auto;
  padding: 20px;
  opacity: 1;
}

@media screen and (max-width: 700px) {
  .accordion {
    width: 90%;
  }
}

/* NFT SellBook */
.nft__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-collapse: collapse;
}

.nft__table__max__height::-webkit-scrollbar {
  display: none !important;
}
.nft__table__header {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  background-color: rgba(0, 0, 0, 0.683);
  backdrop-filter: blur(3px);
  border-radius: 8px;
  margin-bottom: 10px;
}
.nft__table__header__content {
  margin-bottom: 5px;
}
.nft__table__content,
.nft__table__header__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}
.nft__table__head {
  width: 100%;
  display: flex;
}
.nft__table__content > .nft__table__row {
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.448);
  backdrop-filter: blur(8px);
  border-radius: 8px;
}
.nft__table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding: 10px 20px;
  font-size: 12px;
}
.nft__table__row:nth-child(1) {
  color: #fff;
}
.nft__table__row__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nft__table__row__item.collectible button,
.nft__market__button__group button,
.nft__add__cart__button,
.nft__del__cart__button {
  background-color: #00050e;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.nft__table__row__item.collectible button:nth-child(1),
.nft__buy__button {
  background-color: #30cc7ec1;
  color: #031f11;
}
.nft__table__row__item.collectible button:nth-child(2),
.nft__bid__button {
  background-color: #ff0000c1;
  color: #fff;
}
.nft__buy__button {
  margin-right: 5px;
  background-color: #30cc7ec1 !important;
}
.nft__bid__button {
  margin-left: 5px;
  background-color: #8e30ccc1 !important;
}
.nft__table__row__item:first-child {
  width: 7%;
  color: rgb(248, 110, 60);
}
.nft__table__row__item:nth-child(2) {
  width: 60%;
}
.nft__table__row__item:nth-child(3) {
  width: 15%;
}
.nft__table__row__item:nth-child(4) {
  width: 12%;
}
.nft__table__row__item:nth-child(5) {
  width: 15%;
  align-self: flex-end !important;
  text-align: right;
}
.nft__table__row__item.nft__instance__actions:nth-child(5) {
  width: 25%;
  align-self: flex-end !important;
  text-align: right;
}
.nft__table__row__item.nft__table__market:first-child {
  width: 10%;
}
.nft__table__row__item.nft__table__market:nth-child(2) {
  width: 25%;
}
.nft__table__row__item.nft__table__market.nft__table__market__series:nth-child(
    2
  ) {
  width: 20%;
}
.nft__table__row__item.nft__table__market:nth-child(3) {
  width: 22%;
}
.nft__table__row__item.nft__table__market:nth-child(4) {
  width: 15%;
}
.nft__table__row__item.nft__table__market.nft__table__market__price:nth-child(
    4
  ) {
  width: 25%;
}

.nft__table__row__item.nft__table__market:nth-child(5) {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.nft__table__row__item.nft__table__market.nft__table__market__buttons:nth-child(
    5
  ) {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.nft__table__row__item.nft__table__market:nth-child(6) {
  width: 5%;
  /* align-self: flex-end !important; */
  text-align: right;
}
.nft__table__row__item.nft__table__market.nft__table__market__actions:nth-child(
    6
  ) {
  width: 12%;
  padding-left: 10px;
}
.nft__table__auction {
  width: 7% !important;
}
.nft__table__auction:nth-child(4) {
  width: 20% !important;
}
.nft__table__auction:nth-child(5) {
  width: 20% !important;
  justify-content: flex-start !important;
}
.nft__table__row__item.transaction__history__table:first-child {
  width: 7%;
}
.nft__table__row__item.transaction__history__table:nth-child(2) {
  width: 20%;
}
.nft__table__row__item.transaction__history__table:nth-child(3) {
  width: 13%;
}
.nft__table__row__item.transaction__history__table:nth-child(4) {
  width: 15%;
}
.nft__table__row__item.transaction__history__table:nth-child(5) {
  width: 15%;
}
.nft__table__row__item.transaction__history__table:nth-child(6) {
  width: 15%;
}
.nft__table__row__item > input[type='checkbox'] {
  margin-right: 5px;
  width: 28px;
  height: 28px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.nft__table__row__item input[type='checkbox']:checked {
  content: '\2713';
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  color: #f3f3f3;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  accent-color: #30cc7e;
  color-scheme: dark;
}
.pagination__action__buttons ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 20px auto;
}
.pagination__action__buttons ul li {
  margin-right: 15px;
}
.pagination__action__buttons ul li button {
  background-color: #30cc7ec1;
  border: 1px solid #9edc2b;
  color: rgb(37, 39, 3);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.pagination__action__buttons ul li button.previous {
  background-color: #ff0000c1;
  color: #fff;
}
.pagination__action__buttons ul li button.previous.disabled {
  background-color: #00050e !important;
  color: #fff;
}
.pagination__action__buttons ul li button.next {
  background-color: #00050e !important;
  color: #fff;
}
.pagination__action__buttons ul li button.active {
  background-color: #00050e !important;
  color: rgb(161, 7, 7);
}
.airdrop__button {
  padding: 10px 25px;
  background-image: linear-gradient(to right, #2e1134, #64256f);
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
  font-weight: bold;
  color: #fff;
}
.airdrop__button:hover {
  background-image: linear-gradient(to right, #2e1134, #64256f);
  color: #ffd68b;
}

.airdrop__button[disabled] {
  background-image: linear-gradient(to right, #cacaca, #d7d6d7) !important;
  color: #2e1134;
  cursor: not-allowed;
}

.market__transaction__header {
  text-align: left !important;
}

.curator__card__wrapper h1 {
  font-size: 25px;
  font-weight: bold;
}
.curator__card__wrapper.enterprise h1 {
  color: #7937f0;
}
.curator__card__wrapper.gold h1 {
  color: #cc7f4f;
}
.curator__card__wrapper.silver h1 {
  color: #949493;
}
.curator__card__wrapper.bronze h1 {
  color: #937d58;
}
.curator__card__wrapper .trex__buy__button {
  background-image: linear-gradient(to right, #2e1134, #64256f) !important;
  color: #fff;
  border-radius: 8px;
  padding: 10px 25px;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
}
input.trex__buy__button {
  background-image: linear-gradient(to right, #2e1134, #64256f) !important;
  color: #fff;
  border-radius: 8px;
  padding: 10px 25px;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
  border: none !important;
}
.trex__mint__type__button {
  background: none !important;
  border: 1px solid gray;
  border-radius: 8px;
  padding: 8px;
  width: 200px;
  margin: 0px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trex__mint__type__button.active {
  background: none !important;
  border: 3px solid rgb(128, 128, 128);
  border-radius: 8px;
  padding: 6px;
  text-align: center;
}
.trex__mint__type__button div h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px!important;
  text-align: center;
}
.trex__mint__type__button div p {
  color: lightgray !important;
  font-size: 12px;
  text-align: center;
}
